<template>
  <div class="zone">
    <TJDetailTitle :titleData="'基本信息'" />
    <TJDetailMenu :dataList="dataList" />

    <TJDetailTitle :titleData="'产品图片'" />
    <el-image
      style="width: 300px; height: 300px"
      :src="detailData.image"
      alt="暂无图片"
      :fit="fit"
    ></el-image>
    <TJDetailTitle :titleData="'宣传彩页'" />
    <el-button type="primary" v-print="'#printMe'" @click="goPDF(detailData.colour_page)" :disabled="!detailData.colour_page">{{detailData.colour_page?'查看彩页':'暂无彩页'}}</el-button>
    
    <!-- <div class="printBox">
      
      <embed 
        id="printMe"
        :src="detailData.colour_page"
        alt=""
        width="400"
        height="600" />
    </div> -->

  </div>
</template>

<script>
import apiDetail from '@/api/searchDetail.js'
import TJDetailTitle from '@/components/TJDetailTitle'
import TJDetailMenu from '@/components/TJDetailMenu'
export default {
  name: 'ApparatusDetail',
  components: { TJDetailTitle, TJDetailMenu },
  data() {
    return {
      fit: '',
      url: '',
      detailId: '',

      detailData: {},

      dataList: [
        {
          name: '注册证号',
          value: '',
          width: '33%',
        },
        {
          name: '规格',
          value: '',
          width: '33%',
        },
        {
          name: '产品名称',
          value: '',
          width: '33%',
          rightBorder: false,
        },
        {
          name: '是否在售',
          value: '',
          width: '33%',
          bottomBorder: false,
        },
        {
          name: 'DI',
          value: '',
          width: '33%',
          bottomBorder: false,
        },
      ],
    }
  },
  created() {
    this.detailId = this.$route.query.id
    this.gitproductDetail()
  },
  methods: {
    gitproductDetail() {
      apiDetail.productDetail({ inventory_id: this.detailId }).then((res) => {
        this.detailData = res.data
        this.dataList[0].value = this.detailData.inventory_id
        this.dataList[1].value = this.detailData.platform__standard
        this.dataList[2].value = this.detailData.platform_unique
        this.dataList[3].value = this.detailData.is_available ? '是' : '否'
        this.dataList[4].value = this.detailData.di
      })
    },
    goPDF(pdfUrl) {
       window.open(pdfUrl, '_blank');
    },
  },
}
</script>

<style lang="scss" scoped>
.printBox{
  margin-top: px(20);
}
</style>
